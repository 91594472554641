/* eslint-disable camelcase */
import I18n from '../../locales/I18n'
import { Option, Skill } from '../../types/filter'

interface KnowledgeNode {
  uid?: string
  name?: string
  average_memory_level?: number
  disabled?: boolean
}

interface RiseUpData {
  knowledge_nodes: KnowledgeNode[]
  inactive_knowledge_nodes?: KnowledgeNode[]
}

interface ObjectiveValues {
  knowledge_nodes?: KnowledgeNode[]
}

interface Objective {
  uid: string
  values?: ObjectiveValues
}

interface LearningProgram {
  uid: string
  values: any
}

interface Data {
  objectives?: Objective[]
  learning_programs?: LearningProgram[]
}

export const knowledgeNodesLevel = (
  data: Data,
  skills: Skill[],
  selectedTheme: Option,
  riseUpData?: RiseUpData
) => {
  let knowledgeNodes =
    data?.objectives?.find(elem => elem?.uid === selectedTheme.uid)?.values?.knowledge_nodes || []

  if (riseUpData) {
    knowledgeNodes = [...riseUpData.knowledge_nodes]
  }
  if (riseUpData?.inactive_knowledge_nodes && riseUpData?.inactive_knowledge_nodes?.length > 0) {
    riseUpData.inactive_knowledge_nodes.forEach(el => {
      knowledgeNodes.push({ ...el, disabled: true })
    })
  }

  return knowledgeNodes.map((kn: any) => {
    const target = skills.find(skill => skill?.uid === kn?.uid)
    const getSkillName = () => {
      if (riseUpData) {
        // for riseup client, we have to use translation of the knowledge node name, so we use the knowledge node name
        return kn?.name || target?.name || ''
      }
      return target?.name || ''
    }
    return {
      label: getSkillName(),
      value: ((kn?.average_memory_level || 1) * 100) / 6 || 0,
      disabled: kn?.disabled
    }
  })
}

export const getLegendOfLevel = (level: number, scale: 'percentage' | 'cog' = 'percentage') => {
  let levels = [0, 2, 4, 6]

  if (scale === 'percentage') {
    levels = levels.map(lvl => (lvl * 100) / 6)
  }

  if (level < levels[1]) {
    return I18n.t('progress.some_days')
  } else if (level < levels[2]) {
    return I18n.t('progress.a_week')
  } else if (level < levels[3]) {
    return I18n.t('progress.some_weeks')
  } else {
    return I18n.t('progress.some_months')
  }
}
export function useCiblingDatas(data, body, tab?: 'usage' | 'progression' | 'engagement') {
  const hasContext = body.objective_uid.length > 0

  if (hasContext && tab !== 'usage') {
    // Return objective datas
    const objective = (data?.objectives || []).filter(obj => {
      return obj.uid === body.objective[0].uid
    })
    return { datas: objective.length > 0 ? objective[0].values : {} }
  } else {
    // Return experience datas
    const learningProgram = (data?.learning_programs || []).filter(lp => {
      return lp.uid === body.learning_program_uid[0]
    })
    return { datas: learningProgram.length > 0 ? learningProgram[0].values : {} }
  }
}
