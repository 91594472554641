import { domoscioAccess } from '../../../api/domoscio_access'
import I18n from '../../../locales/I18n'
import { ProgressLevels, ReviewStatus } from './types'

// Get objective scoped knowledge_nodes infos
export async function getAverageMemoryLevel(objectives: number[], studentUid: string) {
  return await domoscioAccess.getAverageMemoryLevel({
    objective_id: objectives,
    student_uid: studentUid,
    group_by: ['objective_id', 'knowledge_node_id']
  })
}

// Get student scoped objective_students infos
export async function getObjectiveStudents(studentUid: string) {
  return await domoscioAccess.getObjectiveStudents({
    student_uid: studentUid
  })
}

export const formatCourseDate = (date: Date) => {
  // Extract the day, month, and year
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getUTCFullYear()

  // Format the date as "dd/mm/yyyy"
  return `${day}/${month}/${year}`
}

export const progressFormater = (data: ProgressLevels) => {
  const statusTransformer = {
    pending: 'to_do' as const,
    late: 'late' as const
  }

  const getPotentialLevel = (status: ReviewStatus, value: number, isActive) => {
    if (status === 'uptodate' || !value || !isActive) {
      return undefined
    } else {
      return {
        status: statusTransformer[status],
        value: Math.floor(value)
      }
    }
  }
  return data.map(objective => {
    const name = objective.name
    const percentage = Math.floor(objective.average_memory_level)
    const date = formatCourseDate(new Date(objective.subscription_date))
    const isActive = objective.active
    const percentage2 = getPotentialLevel(
      objective.status,
      objective.potential_average_memory_level,
      objective.active
    )
    const skills = objective.knowledge_nodes.map(skill => {
      return {
        name: skill.name,
        percentage: Math.floor(skill.memory_level),
        isActive: skill.active,
        helpText: skill.active ? undefined : I18n.t('home.memo_inactive_tooltip'),
        percentage2: getPotentialLevel(skill.status, skill.potential_memory_level, skill.active)
      }
    })
    return {
      name,
      date,
      isActive,
      percentage,
      percentage2,
      skills
    }
  })
}
