/* eslint-disable camelcase */
import React, { createContext, useContext } from 'react'
import {
  EntityFilterContext,
  ExperiencesFilterContext,
  DatesFilterContext,
  ObjectivesFilterContext
} from './filterContext'

interface AuxProps {
  children: any
}
type UIDArray = Array<string | undefined>
export type BodyData = {
  student_group_uid: UIDArray
  learning_program_uid: Array<string | undefined>
  start_date: string | undefined
  end_date: string | undefined
  objective_uid: Array<string | undefined>
}

const student_group_uid: UIDArray = []
const learning_program_uid: Array<string | undefined> = []
const start_date: string | undefined = ''
const end_date: string | undefined = ''
const objective_uid: Array<string | undefined> = []
const FilterBodyContext = createContext<BodyData>({
  student_group_uid,
  learning_program_uid,
  start_date,
  end_date,
  objective_uid
})
const FilterBodyContextProvider = ({ children }: AuxProps) => {
  const { selectedEntity } = useContext(EntityFilterContext)
  const { selectedExperience } = useContext(ExperiencesFilterContext)
  const { filteredDates } = useContext(DatesFilterContext)
  const { selectedObjective } = useContext(ObjectivesFilterContext)

  const bodyData: BodyData = {
    student_group_uid: [],
    learning_program_uid: [],
    start_date: '',
    end_date: '',
    objective_uid: []
  }

  if (selectedEntity.length > 0) {
    bodyData.student_group_uid = selectedEntity.map(elem => elem.uid)
  }
  if (selectedExperience.length > 0) {
    bodyData.learning_program_uid = [selectedExperience[0].uid]
  }
  // if we have selected objective, we add it to the body, case scope per objective
  if (
    selectedObjective.length > 0 &&
    selectedObjective[0].id !== 'all' &&
    selectedObjective[0].id !== 'no'
  ) {
    bodyData.objective_uid = [selectedObjective[0].uid]
  }
  if (filteredDates.length > 0) {
    const startDate = new Date(filteredDates[0])
    // add one hour before converting to iso format(iso format is will substarct one hour and at the final we will get the same time )
    startDate.setHours(startDate.getHours() + 1)
    bodyData.start_date = startDate.toISOString()
    // take next day of selected filter, because second selected date is included in the filter
    const endDate = new Date(filteredDates[1] || filteredDates[0])
    endDate.setDate(endDate.getDate() + 1)
    bodyData.end_date = endDate.toISOString()
  }

  return <FilterBodyContext.Provider value={bodyData}>{children}</FilterBodyContext.Provider>
}

export { FilterBodyContextProvider, FilterBodyContext }
