/* eslint-disable camelcase */
import React, { useContext } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDomToolkit } from '../../../styles/dom-toolkit'
import { domoscioAccess } from '../../../api/domoscio_access'
import { Loader, AverageMemoryLevels, NavButton } from '@domoscio/domoscio-ui'
import '../lock.scss'
import { useRiseUpClient } from '../../../hooks/useRiseUpClient'
import { ProgressLevels } from './types'
import { TLoading } from '../../../types/global'
import { progressFormater } from './utils'
import {
  ExperiencesFilterContext,
  ObjectivesFilterContext
} from '../../../contexts/parameters/filterContext'
import { Session } from '../../../contexts/parameters'
import I18n from '../../../locales/I18n'

/*
 * Main StudentScreen fonction
 */

interface BodyData {
  learning_program_uid?: string
  objective_uid?: string
  student_uid: string
  learning_program_id?: number
}
function StudentScreen() {
  const [status, setStatus] = React.useState<TLoading>('idle')
  const [progressData, setProgressData] = React.useState<ProgressLevels | null>(null)
  const classes: any = useDomToolkit()
  const { selectedExperience } = useContext(ExperiencesFilterContext)
  const { selectedObjective } = useContext(ObjectivesFilterContext)
  const session = useContext(Session.State)

  const navigate = useNavigate()
  const location = useLocation()
  const { primaryColor, isRiseup } = useRiseUpClient()
  const title =
    location.state?.name && location.state?.name !== ' '
      ? location.state?.name
      : location.state?.uid

  const getApiBody = () => {
    if (isRiseup) {
      return {
        learning_program_id: Number(session?.options?.analytics?.learning_program_id),
        student_uid: location.state?.uid,
        lang: I18n.locale
      } as BodyData
    } else {
      const body = {
        learning_program_uid: selectedExperience[0]?.uid,
        student_uid: location.state?.uid
      } as BodyData
      if (selectedObjective[0]?.id !== 'all') {
        body.objective_uid = selectedObjective[0]?.uid
      }
      return body
    }
  }

  const getMemorizeProgression = () => {
    setStatus('loading')
    domoscioAccess
      .getMemorizeProgression(getApiBody())
      ?.then((data: { objectives?: ProgressLevels }) => {
        if (Array.isArray(data?.objectives)) {
          setProgressData(data?.objectives)
          setStatus('success')
        } else {
          setStatus('error')
        }
      })
      ?.catch(() => {
        setStatus('error')
      })
  }
  React.useEffect(() => {
    getMemorizeProgression()
    // eslint-disable-next-line
  }, [])

  const isSectionsIsopen = isRiseup || selectedObjective[0]?.id !== 'all'

  const getComponent = () => {
    if (status === 'error') {
      return (
        <div className='errorStudentScreen'>
          <p>{I18n.t('error.dataTable')}</p>
          <NavButton handleClick={() => getMemorizeProgression()} isActive={true}>
            {I18n.t('error.retry')}
          </NavButton>
        </div>
      )
    } else if (status === 'loading' || status === 'idle' || !progressData) {
      return (
        <div className='errorStudentScreen'>
          <Loader />
        </div>
      )
    } else {
      return (
        <div style={{ marginTop: 50 }}>
          {progressFormater(progressData).map((el, i) => (
            <div key={i}>
              <AverageMemoryLevels
                {...el}
                isSectionOpen={isSectionsIsopen}
                primaryColor={isRiseup ? primaryColor : undefined}
              />
            </div>
          ))}
        </div>
      )
    }
  }

  return (
    <div className='StudentScreen'>
      <div className={`${classes.domFlex} ${classes.domFlexMiddle}`}>
        <button
          className={`back-button ${classes.domBgPrimary} ${classes.domBorder0} ${classes.domCursorPointer}`}
          onClick={() => navigate(-1)}
        >
          <MdArrowBack size={24} color={'#fff'} />
        </button>
        <div className={`${classes.domMarginLeft} ${classes.domText20}`}>{title}</div>
      </div>
      {getComponent()}
    </div>
  )
}

export default StudentScreen
