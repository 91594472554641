import React from 'react'
import TitleScreen from '../../../components/TitleScreen'
import Table from '../../../components/DataTable/Table'
import { AbsoluteNumber, EvolutionGraph, StatLoader } from '@domoscio/domoscio-ui'
import I18n from '../../../locales/I18n'

import './MainActivityScreenHub.scss'
import { Theme } from '../../../contexts/parameters'
import { prepareHubActivityData } from './utils'
import { useFetchAnalyticsDatas } from '../../../hooks/useFetchAnalyticsDatas'

function ActivityScreenHub() {
  const { data, isLoading, isSuccess } = useFetchAnalyticsDatas({
    tab: 'HubUsage'
  })

  const theme = React.useContext(Theme.State)
  const primaryColor = theme?.domBgPrimary
  if (isLoading) {
    return <StatLoader primaryColor={primaryColor} />
  }

  const engagementData =
    isSuccess && data?.body?.learning_programs?.length > 0
      ? prepareHubActivityData(data?.body?.learning_programs[0]?.values)
      : prepareHubActivityData([])

  return (
    <div className='ActivityScreenHub'>
      <div className='step3'>
        <TitleScreen titleScreen={I18n.t('activity.hub_title_activity')} />
      </div>
      {engagementData.map((elem, index) => {
        return (
          <div key={index} className='activityContainerHub'>
            <div className='componentAbsoluteNumber'>
              <AbsoluteNumber {...elem.absolute} />
            </div>
            <div className='componentEvolutionGraph'>
              <EvolutionGraph {...elem.evolution} primaryColor={primaryColor} />
            </div>
          </div>
        )
      })}

      <Table page='usage' />
    </div>
  )
}

export default ActivityScreenHub
