import React, { useContext } from 'react'
import {
  AbsoluteNumber,
  Percentage,
  Radar,
  StatLoader,
  MultiPercentage
} from '@domoscio/domoscio-ui'
import TitleScreen from '../../../components/TitleScreen'
import I18n from '../../../locales/I18n'
import {
  MdAlarm,
  MdCheckCircleOutline,
  MdOutlineGroups,
  MdOutlinePlace,
  MdStarOutline
} from 'react-icons/md'
import { Theme, Client } from '../../../contexts/parameters'
import Table from '../../../components/DataTable/Table'
import {
  ExperiencesFilterContext,
  ObjectivesFilterContext,
  SkillsContext
} from '../../../contexts/parameters/filterContext'
import {
  getLearnersCount,
  getAvgPositionings,
  getMultiGraphDatas,
  getSkillsOfSelectedObjective,
  getSkillsCount
} from './utils'

import './ProgressScreenHub.css'
import ProgressPositioning from './subComponents/ProgressPositioning'
import { checkIfAllFetchesSuccessed, millisToMinutesAndSeconds } from '../../../utils/shared'
import { useFetchAnalyticsDatas } from '../../../hooks/useFetchAnalyticsDatas'

function ProgressionScreenHub() {
  const client = React.useContext(Client.State) as any

  const theme = React.useContext(Theme.State)
  const primaryColor = theme?.domBgPrimary
  const secondaryColor = theme?.domBgSecondary

  const { data, status } = useFetchAnalyticsDatas({
    tab: 'HubProgression'
  })

  const { skills } = useContext(SkillsContext)
  const { selectedObjective, objectiveFilters: objectives } = useContext(ObjectivesFilterContext)
  const { selectedExperience } = useContext(ExperiencesFilterContext)

  // wait for data of selected them
  const { dataOfSelectedTheme, isThemSelected } = checkIfAllFetchesSuccessed(
    data,
    selectedObjective,
    selectedExperience,
    status
  )
  const getRadarDataOfSelectedTheme = () => {
    const selectedObj = objectives.objectivesOfSelectedExp.filter(
      elem => elem.id === selectedObjective[0].id
    )[0]
    return getSkillsOfSelectedObjective(dataOfSelectedTheme, skills, selectedObj)
  }

  const isAutoPositionning = () => {
    const selectedObj = objectives.objectivesOfSelectedExp.filter(
      elem => elem.id === selectedObjective[0].id
    )[0]
    const positioningType = selectedObj?.adaptive_options?.positioning_type
    return positioningType === 'SelfPosEvaluation' || positioningType === 'SelfPosSelection'
  }

  return client && status === 'success' ? (
    <div className='ProgressScreenHub'>
      <div className='mainProgressionHub'>
        <div className={`step3 ${!isThemSelected ? 'noRadar' : ''}`}>
          <div className='smallGraphs'>
            <div className='componentAbsoluteNumber step4'>
              <AbsoluteNumber
                icon={<MdOutlineGroups />}
                containerClassName='small_container'
                data={getLearnersCount(dataOfSelectedTheme)}
                title={I18n.t('progress.learners', {
                  count: getLearnersCount(dataOfSelectedTheme) || 0
                })}
                generalTooltipText={I18n.t('engagement.tooltips.learners')}
              />
            </div>
            <div className='componentAbsoluteNumber'>
              <AbsoluteNumber
                icon={<MdStarOutline />}
                containerClassName='small_container'
                title={I18n.t('progress.skills', {
                  count: getSkillsCount(dataOfSelectedTheme) || 0
                })}
                generalTooltipText={I18n.t(
                  `progress.tooltips.hub_skill_${isThemSelected ? 'theme' : 'general'}`
                )}
                data={getSkillsCount(dataOfSelectedTheme)}
              />
            </div>
            <div className='componentAbsoluteNumber'>
              <AbsoluteNumber
                icon={<MdOutlinePlace />}
                containerClassName='small_container'
                title={I18n.t('activity.hub_quiz_done', {
                  count: getAvgPositionings(dataOfSelectedTheme) || 0
                })}
                generalTooltipText={I18n.t(
                  `progress.tooltips.hub_quiz_done${isThemSelected ? '' : '_general'}`
                )}
                data={getAvgPositionings(dataOfSelectedTheme)}
              />
            </div>
          </div>
          {isThemSelected ? (
            <>
              <TitleScreen titleScreen={I18n.t('progress.hub_title_first')} icon={null} />
              <div className='graphes'>
                <div className={`col1 ${!isThemSelected ? 'noRadar' : ''}`}>
                  <div className='percentage'>
                    <Percentage
                      progress={dataOfSelectedTheme?.avg_perc_validated_kn}
                      containerClassName='medium_container'
                      title={I18n.t('progress.validated_skills')}
                      generalTooltipText={I18n.t('progress.tooltips.validated_skills')}
                      icon={<MdCheckCircleOutline />}
                      isZero={dataOfSelectedTheme?.avg_perc_validated_kn === 0}
                    />
                  </div>
                  <div className='multiSkillProgress'>
                    <MultiPercentage
                      key={selectedObjective[0].uid} // 👈 force rerender if selected themen n the filter chenged
                      highlightSelection={true}
                      title={I18n.t('progress.hub_learner_achived_objective')}
                      datas={
                        getMultiGraphDatas(dataOfSelectedTheme, skills).length > 0
                          ? getMultiGraphDatas(dataOfSelectedTheme, skills)
                          : [{ label: '-', value: 0 }]
                      }
                      icon={<MdStarOutline />}
                      generalTooltipText={I18n.t('progress.tooltips.hub_learner_achived_objective')}
                    />
                  </div>
                </div>

                <div className='col2'>
                  <Radar
                    initialData={getRadarDataOfSelectedTheme()?.radarData}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    infoText={
                      getRadarDataOfSelectedTheme()?.isTherotical
                        ? I18n.t('progress.tooltips.radar_other')
                        : I18n.t('progress.tooltips.radar')
                    }
                    displayRadar={getRadarDataOfSelectedTheme()?.radarData.length > 0}
                    numberOfScales={getRadarDataOfSelectedTheme()?.scale}
                    chartSize={window?.screen?.width < 800 ? 600 : 700}
                    title={I18n.t('progress.radar_title')}
                    theoretical={getRadarDataOfSelectedTheme()?.isTherotical}
                    initialDisplay={{
                      initialLevel: getRadarDataOfSelectedTheme()?.isDisplayInitLevel,
                      actualLevel: getRadarDataOfSelectedTheme()?.isDisplayFinalLevel,
                      targetLevel: true
                    }}
                  />
                </div>
              </div>
              <div className='secondSection'>
                {isAutoPositionning() ? (
                  <div className='autoPos'></div>
                ) : (
                  <>
                    {' '}
                    <TitleScreen titleScreen={I18n.t('progress.hub_title_second')} icon={null} />
                    <ProgressPositioning
                      dataOfSelectedTheme={dataOfSelectedTheme}
                      objectives={objectives}
                      selectedObjective={selectedObjective}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              {dataOfSelectedTheme?.avg_time_saved ? (
                <div className='componentAbsoluteNumber'>
                  <AbsoluteNumber
                    icon={<MdAlarm />}
                    containerClassName='small_container'
                    title={I18n.t('progress.saved_time')}
                    generalTooltipText={I18n.t('progress.tooltips.saved_time')}
                    data={millisToMinutesAndSeconds(dataOfSelectedTheme?.avg_time_saved)}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className='percentage'>
                <Percentage
                  progress={dataOfSelectedTheme?.avg_perc_validated_kn}
                  containerClassName='medium_container'
                  title={I18n.t('progress.validated_skills')}
                  generalTooltipText={I18n.t('progress.tooltips.validated_skills')}
                  icon={<MdCheckCircleOutline />}
                  isZero={dataOfSelectedTheme?.avg_perc_validated_kn === 0}
                />
              </div>
            </>
          )}
        </div>
        <Table page='progression' />
      </div>
    </div>
  ) : (
    <StatLoader primaryColor={primaryColor} />
  )
}

export default ProgressionScreenHub
