import React, { useContext } from 'react'

import '../lock.scss'
import TitleScreen from '../../../components/TitleScreen'
import Card from '../../../components/cards/Card'
import Table from '../../../components/DataTable/Table'
import { AbsoluteNumber, EvolutionGraph, StatLoader, Percentage } from '@domoscio/domoscio-ui'
import './ActivityScreenLock.scss'
import I18n from '../../../locales/I18n'
import { useCiblingDatas } from '../utils'
import { FilterBodyContext } from '../../../contexts/parameters/filterBodyContext'
import { Theme } from '../../../contexts/parameters'
import {
  absoluteNumbersElements,
  evolutionGraphElements,
  percentageElements,
  pieChartElements
} from './datas'
import { useFetchAnalyticsDatas } from '../../../hooks/useFetchAnalyticsDatas'
import { useRiseUpClient } from '../../../hooks/useRiseUpClient'

const ActivityScreenLock = () => {
  const body = useContext(FilterBodyContext)
  const { isRiseup } = useRiseUpClient()

  const { data, isLoading } = useFetchAnalyticsDatas({
    tab: 'LockUsage'
  })
  const { datas } = isRiseup ? { datas: data?.values } : useCiblingDatas(data?.body, body, 'usage')

  const theme = React.useContext(Theme.State)
  const primaryColor = theme?.domBgPrimary

  if (isLoading) {
    return <StatLoader primaryColor={primaryColor} />
  }

  return (
    <div className='ActivityScreenLock'>
      <div className='step3'>
        <TitleScreen
          titleScreen={I18n.t('activity.learners.other')}
          data-testid='TitleScreenActivityLock'
        />
        {/* Learners */}
        <div className='firstLine'>
          <div className='componentAbsoluteNumber'>
            {/* Count Students */}
            <AbsoluteNumber {...absoluteNumbersElements(datas, 'learners', isRiseup)} />
          </div>
          <div className='componentAbsoluteNumber small-vw'>
            {/* Count reviews */}
            <AbsoluteNumber {...absoluteNumbersElements(datas, 'reviews', isRiseup)} />
          </div>
          <div className='componentEvolutionGraph'>
            {/* Monthly Activity */}
            <EvolutionGraph
              {...evolutionGraphElements(datas, 'learners')}
              primaryColor={primaryColor}
            />
          </div>
        </div>
        {/* Reviews */}
        <div className='firstLine'>
          <div className='componentAbsoluteNumber  lg-vw'>
            {/* Count reviews */}
            <AbsoluteNumber {...absoluteNumbersElements(datas, 'reviews', isRiseup)} />
          </div>
          <div className='componentEvolutionGraph'>
            {/* Monthly Activity */}
            <EvolutionGraph
              {...evolutionGraphElements(datas, 'reviews', isRiseup)}
              primaryColor={primaryColor}
            />
          </div>
        </div>

        {/* Challenges */}
        {!isRiseup && (
          <>
            <TitleScreen titleScreen={I18n.t('activity.title_activity')} />
            <div className='firstLine'>
              <div className='componentPercentage'>
                {/* Percentage mobile users */}
                <Percentage {...percentageElements(datas)} />
              </div>
              <div className='componentPercentage mobileUsage'>
                {/* Percentage mobile users */}
                <Card {...pieChartElements(datas, primaryColor)} />
              </div>
            </div>{' '}
          </>
        )}
      </div>
      <Table page='usage' />
    </div>
  )
}

export default ActivityScreenLock
