import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'
import { GetTableResponse, QueryBody, TFilters } from './types'
import { formatDataHub, getColumnNamesHub, modifySort } from './hubUtils'
import { formatDataLock, getColumnNamesLock } from './lockUtils'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

export const exportToExcel = async (excelData: any, headers: any, fileName: string) => {
  excelData = excelData.map((data: any) => {
    const newData = {} as any
    headers.forEach((header: any) => {
      newData[header.label] = data[header.key] || '-'
    })
    return newData
  })

  const ws = XLSX.utils.json_to_sheet(excelData)
  const excelBuffer = XLSX.write(
    {
      Sheets: { data: ws },
      SheetNames: ['data']
    },
    { bookType: 'xlsx', type: 'array' }
  )
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

interface Args {
  backEndData: GetTableResponse
  scope: 'objectives' | 'learning_programs'
  objectiveUid: string
  isRiseup: boolean
}

export const getScopedData = ({ backEndData, scope, objectiveUid, isRiseup }: Args) => {
  if (scope === 'learning_programs') {
    return {
      list: backEndData.learning_programs?.data[0]?.values?.students || [],
      totalCount: Number(backEndData?.learning_programs?.recordsFiltered) || 0
    }
  } else {
    const allObjectives = backEndData.objectives?.data
    const scopedObjectiveData = isRiseup
      ? allObjectives[0]
      : allObjectives?.find(obj => obj.objective_uid === objectiveUid)
    return {
      list: scopedObjectiveData?.values?.students || [],
      totalCount: Number(backEndData?.objectives?.recordsFiltered) || 0
    }
  }
}

export const getFormattedTableData = (
  values: GetTableResponse | undefined,
  scope: 'objectives' | 'learning_programs',
  selectedObjectiveUid: string,
  mode: 'hub' | 'lock',
  page: 'progression' | 'usage' | 'engagement',
  options: {
    isRiseup: boolean
    isOnlyQuestions?: boolean
    objectiveFilters?: any
    selectedObjective?: any
  }
) => {
  if (values && values[scope]?.data?.length > 0) {
    const { list, totalCount } = getScopedData({
      backEndData: values,
      scope,
      objectiveUid: selectedObjectiveUid,
      isRiseup: options.isRiseup
    })

    if (mode === 'hub') {
      const isOnlyOnePositioning =
        options.objectiveFilters?.objectivesOfSelectedExp.filter(
          (elem: any) => elem.id === options.selectedObjective[0].id
        )[0]?.attempt_options?.workflow === null
      const { datas, options: tableOptions } = formatDataHub(
        list,
        getColumnNamesHub({ page, scope }),
        isOnlyOnePositioning
      )

      return { datas, options: tableOptions, totalCount }
    } else {
      const { datas, options: tableOptions } = formatDataLock(
        list,
        getColumnNamesLock({
          page,
          isRiseup: options.isRiseup,
          isOnlyQuestions: options.isOnlyQuestions
        }),
        options.isRiseup,
        scope
      )

      return { datas, options: tableOptions, totalCount }
    }
  }
  return {}
}

export const buildQueryBody = (
  page: 'progression' | 'usage' | 'engagement',
  filters: TFilters,
  options: {
    isRiseup: boolean
    scope: 'objectives' | 'learning_programs'
    datas?: any
  }
): QueryBody => {
  let body = {
    type: page,
    page: filters.pageIndex,
    per_page: filters.pageSize,
    search: filters.searchText,
    order: modifySort(filters.sortBy)
  } as QueryBody

  if (options.isRiseup) {
    body = {
      ...body,
      scope: page === 'usage' ? 'learning_programs' : 'objectives',
      start_date: new Date('2010-01-01').toISOString(),
      end_date: new Date().toISOString()
    }
  } else {
    body = {
      ...body,
      scope: options.scope,
      student_group_uid: options.datas?.student_group_uid,
      learning_program_uid: options.datas?.learning_program_uid,
      start_date: options.datas?.start_date,
      end_date: options.datas?.end_date
    }
    if (options.scope === 'objectives') {
      body.objective_uid = options.datas?.objective_uid
    }
  }

  if (filters.searchText === '') {
    delete body.search
  }

  return body
}
