import React, { useContext } from 'react'
import TitleScreen from '../../../components/TitleScreen'
import Table from '../../../components/DataTable/Table'
import { AbsoluteNumber, StatLoader, Percentage } from '@domoscio/domoscio-ui'
import { MdOutlineGroups } from 'react-icons/md'
import I18n from '../../../locales/I18n'
import './MainEngagementScreenHub.scss'
import { Theme } from '../../../contexts/parameters'
import {
  ExperiencesFilterContext,
  ObjectivesFilterContext
} from '../../../contexts/parameters/filterContext'
import { prepareHubEngagementData } from './utils'
import { checkIfAllFetchesSuccessed } from '../../../utils/shared'
import { useFetchAnalyticsDatas } from '../../../hooks/useFetchAnalyticsDatas'

function EngagementScreenHub() {
  const { data, status, isLoading } = useFetchAnalyticsDatas({
    tab: 'HubEngagement'
  })

  const countStudents: number = data?.count_students || 0

  const { selectedObjective } = useContext(ObjectivesFilterContext)
  const { selectedExperience } = useContext(ExperiencesFilterContext)
  const theme = React.useContext(Theme.State)
  const primaryColor = theme?.domBgPrimary
  if (isLoading) {
    return <StatLoader primaryColor={primaryColor} />
  }

  // wait for data of selected theme
  const { dataOfSelectedTheme, isThemSelected } = checkIfAllFetchesSuccessed(
    data,
    selectedObjective,
    selectedExperience,
    status
  )

  const engagementData = prepareHubEngagementData(dataOfSelectedTheme, isThemSelected)

  return (
    <div className='EngagementScreenHub'>
      <div className='step3'>
        <TitleScreen
          titleScreen={I18n.t('engagement.title_engagement')}
          data-testid='titleScreenEngagementHub'
        />
        <div className='componentAbsoluteNumber'>
          {/* Count Students */}
          <AbsoluteNumber
            icon={<MdOutlineGroups />}
            containerClassName='small_container'
            data={dataOfSelectedTheme?.nbr_student || 0}
            title={I18n.t('activity.learners', { count: countStudents })}
            generalTooltipText={I18n.t('engagement.tooltips.learners')}
            data-testid='absoluteEngagementHusb'
          />
        </div>
        <div className='componentsPercentage'>
          {engagementData.map((elem, index) => {
            return (
              <div key={index} className='percentage'>
                <Percentage {...elem} />
              </div>
            )
          })}
        </div>
      </div>

      <Table page='engagement' />
    </div>
  )
}

export default EngagementScreenHub
