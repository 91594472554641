import I18n from '../../locales/I18n'
import { StudentData } from './types'

export const columnNamesLock = {
  global: ['uid', 'student_name', 'student_group_name'],
  progression: ['avg_memory_level', 'total_time_spent', 'perc_correct_answers'],
  progressionWithChallenges: [
    'nbr_actions_accepted',
    'nbr_actions_done',
    'nbr_actions_done_easily',
    'nbr_actions_done_with_difficulty',
    'nbr_actions_failed',
    'nbr_actions_abandoned'
  ],
  engagement: ['nbr_sessions', 'status', 'nbr_questions_answered', 'nbr_objectives_started'],
  engagementWithChallenges: ['nbr_actions_in_progress', 'nbr_actions_sent', 'nbr_actions_accepted'],
  usage: ['subscription_date', 'last_activity_date', 'is_mobile_user'],
  ru: {
    progression: [
      'uid',
      'student_name',
      'avg_memory_level',
      'total_time_spent',
      'perc_correct_answers'
    ],
    engagement: ['uid', 'student_name', 'nbr_sessions', 'status', 'nbr_questions_answered'],
    usage: ['uid', 'student_name', 'subscription_date', 'last_activity_date']
  }
}

interface Args {
  page: 'usage' | 'progression' | 'engagement'
  isRiseup: boolean
  isOnlyQuestions?: boolean
}

export const getColumnNamesLock = ({ page, isRiseup, isOnlyQuestions }: Args) => {
  if (isRiseup) {
    const columns = columnNamesLock.ru[page]
    if (page === 'progression') {
      return isOnlyQuestions ? columns : [...columns, ...columnNamesLock.progressionWithChallenges]
    } else if (page === 'engagement') {
      return isOnlyQuestions ? columns : [...columns, ...columnNamesLock.engagementWithChallenges]
    } else {
      return columns
    }
  }
  const columns = columnNamesLock.global as string[]
  if (page === 'progression') {
    return isOnlyQuestions
      ? [...columns, ...columnNamesLock.progression]
      : [...columns, ...columnNamesLock.progression, ...columnNamesLock.progressionWithChallenges]
  } else if (page === 'engagement') {
    return isOnlyQuestions
      ? [...columns, ...columnNamesLock.engagement]
      : [...columns, ...columnNamesLock.engagement, ...columnNamesLock.engagementWithChallenges]
  } else {
    return [...columns, ...columnNamesLock.usage]
  }
}

export const getMemoryLevels = (key: any) => {
  const values = {
    few_days: 'days',
    one_week: 'week',
    several_weeks: 'weeks',
    several_months: 'months'
  }

  return I18n.t(`progress.review_levels.${values[key]}`)
}

export const getStudentStatusColor = (
  status: 'up_to_date' | 'pending_reviews' | 'late_reviews'
) => {
  if (status === 'up_to_date') {
    return 'green'
  } else if (status === 'pending_reviews') {
    return 'orange'
  } else {
    return 'red'
  }
}

const getTrad = (key: string, isRiseup?: boolean) => {
  return I18n.t(`viz.datatable.lock.${isRiseup ? 'riseup.' : ''}${key}`)
}

const getColumnsOptions = (isRiseup: boolean, isObjectiveScope?: boolean) => {
  return {
    uid: {
      trad: getTrad('uid'),
      customColumnStyleOptions: ['uid', 'sticky', 'ellipsis']
    },
    student_name: {
      trad: getTrad('name'),
      customColumnStyleOptions: ['sticky']
    },
    student_group_name: {
      trad: getTrad('student_group'),
      customColumnStyleOptions: ['entity']
    },
    avg_memory_level: {
      trad: getTrad('avg_memory_level', isRiseup),
      tooltip: getTrad('avg_memory_level_tooltip')
    },
    perc_correct_answers: {
      trad: getTrad('perc_correct_answers'),
      customColumnStyleOptions: ['percentage'],
      tooltip: getTrad('perc_correct_answers_tooltip', isRiseup)
    },
    nbr_actions_accepted: {
      trad: getTrad('nbr_actions_accepted'),
      tooltip: getTrad('nbr_actions_accepted_tooltip')
    },
    nbr_actions_done: {
      trad: getTrad('nbr_actions_done'),
      tooltip: getTrad('nbr_actions_done_tooltip')
    },
    nbr_actions_failed: {
      trad: getTrad('nbr_actions_failed')
    },
    nbr_actions_abandoned: {
      trad: getTrad('nbr_actions_abandoned')
    },
    total_time_spent: {
      trad: getTrad('total_time_spent'),
      tooltip: getTrad('total_time_spent_tooltip', isRiseup),
      customColumnStyleOptions: ['time']
    },
    nbr_actions_sent: {
      trad: getTrad('nbr_actions_sent'),
      tooltip: getTrad('nbr_actions_sent_tooltip')
    },
    nbr_actions_done_easily: {
      trad: getTrad('nbr_actions_done_easily')
    },
    nbr_actions_done_with_difficulty: {
      trad: getTrad('nbr_actions_done_with_difficulty')
    },
    last_activity_date: {
      trad: getTrad('last_activity_date'),
      customColumnStyleOptions: ['date']
    },
    is_mobile_user: {
      trad: getTrad('is_mobile_user'),
      customColumnStyleOptions: ['trueOrFalse']
    },
    status: {
      trad: getTrad('status'),
      customColumnStyleOptions: ['colored']
    },
    subscription_date: {
      trad: getTrad('subscription_date'),
      customColumnStyleOptions: ['date']
    },
    nbr_objectives_started: {
      trad: getTrad('nbr_objectives_started'),
      customColumnStyleOptions: [isObjectiveScope ? 'trueOrFalse' : '']
    },
    nbr_sessions: {
      trad: getTrad('nbr_sessions', isRiseup)
    },
    nbr_questions_answered: {
      trad: getTrad('nbr_questions_answered')
    },
    nbr_actions_in_progress: {
      trad: getTrad('nbr_actions_in_progress')
    }
  }
}

const getOptions = (columnsOfScreen: string[], isRiseup: boolean, isObjectiveScope?: boolean) => {
  const options = {} as any
  columnsOfScreen.forEach((key: string) => {
    options[key] = getColumnsOptions(isRiseup, isObjectiveScope)[key]
  })
  return options
}

export const formatDataLock = (
  dataList: StudentData[],
  columnsOfScreen: string[],
  isRiseup: boolean,
  scope: 'objectives' | 'learning_programs'
) => {
  const isObjectiveScope = scope === 'objectives'

  const options = getOptions(columnsOfScreen, isRiseup, isObjectiveScope)
  const formattedDatas = dataList.map((student: any) => {
    const formatedColumns = {} as { [key: string]: any }
    columnsOfScreen.forEach(columnName => {
      const ratioColumns = [...columnNamesLock.progressionWithChallenges, 'nbr_actions_accepted']
      if (ratioColumns.includes(columnName)) {
        formatedColumns[columnName] =
          student[columnName] === null ? null : `${student[columnName]}/${student.nbr_actions_sent}`
      } else if (columnName === 'nbr_objectives_started') {
        if (isObjectiveScope) {
          formatedColumns[columnName] = student.started_objective
        } else {
          formatedColumns[columnName] =
            student[columnName] === null ? null : `${student[columnName]}/${student.nbr_objectives}`
        }
      } else {
        formatedColumns[columnName] = student[columnName] ?? null
      }
    })
    if (formatedColumns?.student_group_name?.includes('default_group_')) {
      formatedColumns.student_group_name = null
    }
    if (formatedColumns.avg_memory_level) {
      formatedColumns.avg_memory_level = getMemoryLevels(formatedColumns.avg_memory_level)
    }
    if (formatedColumns.status) {
      formatedColumns.status = getStudentStatusColor(formatedColumns.status)
    }

    return formatedColumns
  })
  return {
    datas: formattedDatas,
    options
  }
}
