import React from 'react'
import './ProgressData.scss'

import I18n from '../../locales/I18n'
import { SkillLabels, SkillProgress, InformationIcon } from '@domoscio/domoscio-ui'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'
import { MdOutlinePsychology } from 'react-icons/md'

interface Props {
  progressData: any
}
const ProgressData = ({ progressData }: Props) => {
  const { primaryColor } = useRiseUpClient()
  const { isRiseup } = useRiseUpClient()

  if (!progressData) {
    return null
  }

  // memo levlels
  const labals = ['days', 'week', 'weeks', 'months'].map(label =>
    I18n.t(`progress.review_levels.${label}`)
  )

  const formatedData = progressData.map(el => ({
    name: el.label,
    percentage: Math.floor(Number(el.value)),
    isActive: !el.disabled
  }))

  return (
    <div className='progressData'>
      <InformationIcon
        generalTooltipText={I18n.t(
          `progress.tooltips.average_memorization_notion${isRiseup ? 'v2' : ''}`
        )}
        isDataTable={false}
      />
      <section className='progressHeader'>
        <span className='icon'>
          <MdOutlinePsychology />
        </span>
        <div className='title'>
          <div className='title_line1'>
            {I18n.t(`progress.memorization_level_by_notion${isRiseup ? 'v2' : ''}`)}
          </div>
          <div className='title_line2'>
            {formatedData.length + ' ' + I18n.t('progress.skills', { count: formatedData.length })}
          </div>
        </div>
      </section>
      <div className='skillsSection'>
        <SkillLabels labels={labals}>
          {formatedData.map((el, i) => (
            <div key={i}>
              <SkillProgress
                {...el}
                helpText={I18n.t('home.memo_inactive_tooltip')}
                primaryColor={primaryColor}
                isLastElem={i === progressData.length - 1}
              />
            </div>
          ))}
        </SkillLabels>
      </div>
    </div>
  )
}

export default ProgressData
