/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { domoscioAccess } from '../../api/domoscio_access'
import { Client } from '../../contexts/parameters'
import { FilterBodyContext } from '../../contexts/parameters/filterBodyContext'
import {
  ExperiencesFilterContext,
  ObjectivesFilterContext
} from '../../contexts/parameters/filterContext'
import { Loader, CardContainer, DataTable, NavButton } from '@domoscio/domoscio-ui'
import { MdChevronRight, MdExpandMore } from 'react-icons/md'
import { useDomToolkit } from '../../styles/dom-toolkit'
import I18n from '../../locales/I18n'
import './Table.css'
import { useRiseUpClient } from '../../hooks/useRiseUpClient'
import { GetTableResponse, StudentData, TFilters } from './types'
import { TLoading } from '../../types/global'
import { exportToExcel, getFormattedTableData, buildQueryBody } from './utils'

type Props = {
  page: 'progression' | 'usage' | 'engagement'
  isOnlyQuestions?: boolean
}

const Table = ({ page, isOnlyQuestions }: Props) => {
  // 1. State initialization
  const [isShowDataTable, setIsShowDataTable] = useState(false)
  const [apiCallStatus, setApiCallStatus] = useState<TLoading>('idle')
  const [tableData, setTableData] = useState<GetTableResponse>()

  const [filters, setFilters] = useState<TFilters>({
    pageIndex: 1,
    pageSize: 10,
    searchText: ''
  })
  const { isRiseup } = useRiseUpClient()

  // 2. Context init
  const datas = React.useContext(FilterBodyContext)
  const { selectedObjective, objectiveFilters } = React.useContext(ObjectivesFilterContext)
  const { selectedExperience } = React.useContext(ExperiencesFilterContext)
  const client = React.useContext(Client.State)
  const mode = client?.product || 'hub'

  const classes: any = useDomToolkit()
  const navigate = useNavigate()
  const location = useLocation()

  const hasObjectiveContext = datas.objective_uid.length > 0

  const getRuScope = () => {
    if (page === 'usage') {
      return 'learning_programs'
    } else {
      return 'objectives'
    }
  }

  const getScope = () => {
    if (isRiseup) {
      return getRuScope()
    } else if (page === 'usage') {
      return 'learning_programs'
    } else if (hasObjectiveContext) {
      return 'objectives'
    } else {
      return 'learning_programs'
    }
  }

  const scope = getScope()
  console.log('scope', scope)
  const getQueryBody = () => {
    return buildQueryBody(page, filters, {
      isRiseup,
      scope,
      datas
    })
  }
  // Wait for filters before fetching tables
  const bodyReady =
    isRiseup ||
    (datas.learning_program_uid.length > 0 &&
      datas.student_group_uid.length !== 0 &&
      selectedObjective.length > 0)

  // 3. Send query
  const getDatatable = () => {
    setApiCallStatus('loading')
    domoscioAccess.getDatatable(getQueryBody()).then((res: GetTableResponse) => {
      if (res.learning_programs) {
        setTableData(res)
        setApiCallStatus('success')
      } else {
        setApiCallStatus('error')
      }
    })
  }

  useEffect(() => {
    if (bodyReady) {
      getDatatable()
    }
  }, [bodyReady, filters.pageIndex, filters.searchText, filters.pageSize, scope, filters.sortBy])

  // Add specific dt props by screen name
  let datatableProps = {} as any
  if (page === 'engagement') {
    datatableProps = {
      isColored: true,
      colorDescriptions: {
        green: I18n.t(`datatable.${mode}.green_description`),
        orange: I18n.t(`datatable.${mode}.orange_description${isRiseup ? 'v2' : ''}`),
        red: I18n.t(`datatable.${mode}.red_description${isRiseup ? 'v2' : ''}`)
      }
    }
  }

  const handleRowClicked = (student: StudentData) => {
    const objectiveId = selectedObjective[0]?.id
    const from = location.pathname
    console.log('student', student)
    const userDetails =
      mode === 'lock'
        ? { uid: student.uid, name: student.student_name }
        : { student_name: student.student_name, student_uid: student.uid }

    if (mode === 'lock') {
      navigate('/student', { state: userDetails })
    } else if (objectiveId === 'all') {
      const experienceId = selectedExperience[0].id
      navigate(`/student/learning_programs/${experienceId}`, { state: { ...userDetails, from } })
    } else {
      navigate(`/student/objective/${objectiveId}/adaptive`, { state: { ...userDetails, from } })
    }
    return {}
  }

  const getFormattedData = (args?: GetTableResponse) => {
    const values = args ?? tableData
    return getFormattedTableData(values, scope, selectedObjective[0]?.uid || '', mode, page, {
      isRiseup,
      isOnlyQuestions,
      objectiveFilters,
      selectedObjective
    })
  }

  const handleClickExportDataTable = async () => {
    // Initialize variables for pagination
    const PER_PAGE = 100 // TODO: change before release
    let currentPage = 1
    let allData: any[] = []
    let hasMoreData = true

    try {
      while (hasMoreData) {
        const body = getQueryBody()
        body.page = currentPage
        body.per_page = PER_PAGE

        const res: GetTableResponse = await domoscioAccess.getDatatable(body)

        if (!res.learning_programs) break

        const { datas = [] } = getFormattedData(res)
        allData = [...allData, ...datas]

        // Check if we've received less than PER_PAGE items (meaning it's the last page)
        hasMoreData = datas.length === PER_PAGE
        currentPage++
      }

      // Process the complete dataset
      const DtOptions = getFormattedData(tableData).options
      const DtKeys = Object.keys(DtOptions)
      const data = DataTable.exportUtils.prepareCvsData(
        allData,
        DtKeys,
        DtOptions,
        undefined, // legacy user details key
        datatableProps.colorDescriptions
      )
      const headers = DataTable.exportUtils.prepareCvsHeaders(DtKeys, DtOptions)

      // KPI logs (feature tracking)
      domoscioAccess.postKpiLog('SupervisorExportDatatable')
      const name = location.pathname === '/' ? 'progress' : location.pathname.replace('/', '')

      exportToExcel(data, headers, I18n.t(`screen.${name}`))
    } catch (error) {
      console.error('Error exporting data:', error)
    }
  }

  // 6. Render
  return (
    <CardContainer
      containerClassName='full_container'
      style={{ overflow: 'hidden', marginBottom: 20 }}
    >
      <div className={'dataTableBottom'}>
        <div className={`${classes.domTextSecondary} step5`}>
          <div className='tableExpand' onClick={() => setIsShowDataTable(!isShowDataTable)}>
            <span className={'showMoreIcon'}>
              {!isShowDataTable ? (
                <MdChevronRight size={20} color='#686D76' />
              ) : (
                <MdExpandMore size={20} color='#686D76' />
              )}
            </span>
            <span>{I18n.t('screen.learners')}</span>{' '}
          </div>
        </div>

        <div className={`content ${isShowDataTable ? 'displayTable' : 'hideTable'}`}>
          {!tableData ? (
            <Loader />
          ) : apiCallStatus === 'error' ? (
            <div className='errorDatatable'>
              <p>{I18n.t('error.dataTable')}</p>
              <NavButton handleClick={() => getDatatable()} isActive={true}>
                {I18n.t('error.retry')}
              </NavButton>
            </div>
          ) : (
            <DataTable
              {...datatableProps}
              filters={filters}
              totalCount={getFormattedData().totalCount || 0}
              onFiltersChange={filters => {
                setFilters(prev => ({ ...prev, ...filters }))
              }}
              initialData={getFormattedData().datas || []}
              options={getFormattedData().options || {}}
              onClickRow={handleRowClicked}
              clickable={true}
              onClickExport={handleClickExportDataTable}
              isLoading={apiCallStatus === 'loading'}
            />
          )}
        </div>
      </div>
    </CardContainer>
  )
}

export default Table
