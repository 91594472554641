const formatActivity = (datas: Array<{ date: string | Date; value: number }> | any) => {
  if (!datas || !Array.isArray(datas)) datas = []
  const today = new Date()
  const results = [] as any[]

  // Initialize results for the last 4 months with default values
  for (let i = 0; i < 4; i++) {
    const pastDate = new Date(today.getFullYear(), today.getMonth() - i, 1)
    results.push({
      name: `${pastDate.getMonth() + 1}/${pastDate.getFullYear()}`,
      value: 0
    })
  }

  // Process input data
  datas.forEach(data => {
    const date = new Date(data.date)
    const formattedName = `${date.getMonth() + 1}/${date.getFullYear()}`

    // Find the corresponding month in results and update the value
    const result = results.find(r => r.name === formattedName)
    if (result) {
      result.value = data.value
    }
  })

  // Ensure the results are in the correct order (most recent month first)
  return results.reverse()
}

// Fill datas with empty values
const prependEmptyData = (datas: any) => {
  let date = datas[0].name.split('/').map((d: string) => parseInt(d))
  if (date[0] === 1) {
    date = [12, date[1] - 1]
  } else {
    date = [date[0] - 1, date[1]]
  }
  return [
    {
      name: date.join('/'),
      value: 0
    },
    ...datas
  ]
}

export { formatActivity, prependEmptyData }
