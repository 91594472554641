import I18n from '../../locales/I18n'
import { StudentData } from './types'

export const columnNamesHub = {
  global: ['uid', 'student_name', 'student_group_name'],
  progression: [
    'nbr_positionings_done',
    'nbr_validated_kn',
    'total_time_spent',
    'perc_correct_answers'
  ],
  progression_all: [
    'nbr_positionings_done',
    'nbr_validated_kn',
    'total_time_spent',
    'perc_correct_answers'
  ],
  subGroupColumns: ['nbr_validated_kn', 'total_time_spent', 'perc_correct_answers'],
  engagement: ['status', 'nbr_recommendations_seen'],
  engagement_all: [
    'status',
    'nbr_recommendations_seen',
    'nbr_objectives_started',
    'nbr_objectives_finished'
  ],
  usage: ['subscription_date', 'experience_end_date', 'last_activity_date']
}

interface Args {
  page: 'usage' | 'progression' | 'engagement'
  scope: 'objectives' | 'learning_programs'
}

export const getColumnNamesHub = ({ page, scope }: Args) => {
  const columns = columnNamesHub.global as string[]
  if (page === 'usage') {
    return [...columns, ...columnNamesHub.usage]
  } else {
    return [...columns, ...columnNamesHub[`${page}${scope === 'learning_programs' ? '_all' : ''}`]]
  }
}

const getTrad = (key: string) => {
  return I18n.t(`viz.datatable.hub.${key}`)
}

const getSubColumns = (isOnePos?: boolean) => {
  return {
    initial: {
      trad: getTrad('initial')
    },
    final: {
      trad: getTrad(`${isOnePos ? 'estimated_' : ''}final`)
    }
  }
}

export const getColumnsOptions = (isOnePos?: boolean) => {
  return {
    uid: {
      trad: getTrad('uid'),
      customColumnStyleOptions: ['uid', 'sticky', 'ellipsis']
    },
    student_name: {
      trad: getTrad('student_name'),
      customColumnStyleOptions: ['sticky']
    },
    student_group_name: {
      trad: getTrad('student_group'),
      customColumnStyleOptions: ['entity']
    },
    nbr_recommendations_seen: {
      trad: getTrad('nbr_recommendations_seen'),
      tooltip: getTrad('nbr_recommendations_seen_tooltip')
    },
    nbr_objectives_started: {
      trad: getTrad('nbr_objectives_started'),
      tooltip: getTrad('nbr_objectives_started_tooltip')
    },
    nbr_objectives_finished: {
      trad: getTrad('nbr_objectives_finished'),
      tooltip: getTrad('nbr_objectives_finished_tooltip')
    },
    subscription_date: {
      trad: getTrad('subscription_date'),
      customColumnStyleOptions: ['date']
    },
    experience_end_date: {
      trad: getTrad('experience_end_date'),
      customColumnStyleOptions: ['date']
    },
    last_activity_date: {
      trad: getTrad('last_activity_date'),
      customColumnStyleOptions: ['date']
    },
    nbr_positionings_done: {
      trad: getTrad('nbr_positionings_done'),
      tooltip: getTrad('nbr_positionings_done_tooltip')
    },
    nbr_validated_kn: {
      trad: getTrad('nbr_validated_kn'),
      tooltip: getTrad('nbr_validated_kn_tooltip'),
      customColumnStyleOptions: ['ratio', 'grouped'],
      subColumns: getSubColumns(isOnePos)
    },
    total_time_spent: {
      trad: getTrad('total_time_spent'),
      tooltip: getTrad('total_time_spent_tooltip'),
      customColumnStyleOptions: ['time', 'grouped'],
      subColumns: getSubColumns()
    },
    perc_correct_answers: {
      trad: getTrad('perc_correct_answers'),
      tooltip: getTrad('perc_correct_answers_tooltip'),
      customColumnStyleOptions: ['percentage', 'grouped'],
      subColumns: getSubColumns()
    },
    status: {
      trad: getTrad('status'),
      customColumnStyleOptions: ['colored']
    }
  }
}

const getOptions = (columnsOfScreen: string[], isOnePos: boolean) => {
  const options = {} as any
  columnsOfScreen.forEach((key: string) => {
    options[key] = getColumnsOptions(isOnePos)[key]
  })
  return options
}

export const getStudentStatusColorHub = (
  status: 'up_to_date' | 'pending_actions' | 'no_positioning'
) => {
  if (status === 'up_to_date') {
    return 'green'
  } else if (status === 'pending_actions') {
    return 'orange'
  } else {
    return 'red'
  }
}

const getValidateKn = (
  firstPosValues: number | null,
  secondPosValue: number | null,
  isOnePos: boolean,
  posDone: number,
  totalKn: number,
  estimaedFinal?: number | null
) => {
  if (posDone === 0) {
    return {
      initial: null,
      final: null
    }
  } else if (posDone > 1) {
    return {
      initial: `${firstPosValues}/${totalKn || 0}`,
      final: `${secondPosValue}/${totalKn || 0}`
    }
  } else if (isOnePos) {
    return {
      initial: `${firstPosValues}/${totalKn || 0}`,
      final: `${estimaedFinal || 0}/${totalKn || 0}`
    }
  } else {
    return {
      initial: `${firstPosValues}/${totalKn || 0}`,
      final: null
    }
  }
}

export const formatDataHub = (
  dataList: StudentData[],
  columnsOfScreen: string[],
  isOnePos: boolean
) => {
  const options = getOptions(columnsOfScreen, isOnePos)
  const formattedDatas = dataList.map((student: any) => {
    const formatedColumns = {} as { [key: string]: any }
    columnsOfScreen.forEach(columnName => {
      const subColumns = columnNamesHub.subGroupColumns
      if (subColumns.includes(columnName)) {
        const posDone = student.nbr_positionings_done
        if (columnName.includes('kn')) {
          formatedColumns[columnName] = getValidateKn(
            student[`first_positioning_${columnName}`],
            student[`last_positioning_${columnName}`],
            isOnePos,
            posDone,
            student.nbr_kn,
            student.estimated_nbr_kn_validated
          )
        } else {
          formatedColumns[columnName] = {
            initial: student[`first_positioning_${columnName}`],
            final: student[`last_positioning_${columnName}`]
          }
        }
      } else {
        formatedColumns[columnName] = student[columnName] ?? null
      }
      if (['nbr_objectives_started', 'nbr_objectives_finished'].includes(columnName)) {
        if (student.status === 'no_positioning') {
          formatedColumns[columnName] = '-'
        } else {
          formatedColumns[columnName] = `${student[columnName]}/${student.nbr_objectives}`
        }
      }
      if (columnName === 'nbr_recommendations_seen') {
        if (student.status === 'no_positioning') {
          formatedColumns[columnName] = '-'
        } else {
          formatedColumns[columnName] =
            `${student[columnName]}/${student.nbr_recommendations_received}`
        }
      }
      if (columnName === 'experience_end_date') {
        formatedColumns[columnName] = student.end_date
      }
    })

    if (formatedColumns?.student_group_name?.includes('default_group_')) {
      formatedColumns.student_group_name = null
    }
    if (formatedColumns.status) {
      formatedColumns.status = getStudentStatusColorHub(formatedColumns.status)
    }
    return formatedColumns
  })

  return {
    datas: formattedDatas,
    options
  }
}

export const modifySort = (sortBy?: Array<{ id: string; dir: 'asc' | 'desc' }>) => {
  if (!sortBy || sortBy.length === 0) {
    return []
  } else {
    let id = sortBy[0].id
    if (id.endsWith('initial')) {
      // Remove "initial" and prepend "first_positioning_"
      id = 'first_positioning_' + id.slice(0, -7)
    } else if (id.endsWith('final')) {
      // Remove "final" and prepend "last_positioning_"
      id = 'last_positioning_' + id.slice(0, -5)
    } else if (id === 'uid') {
      id = 'student_uid'
    }
    return [{ column: id, dir: sortBy[0].dir }]
  }
}
